.Keyboard-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.Keyboard-row {
  display: flex;
}

.Keyboard-letter {
  align-items: center;
  background-color: gray;
  border-radius: 5px;
  border: none;
  color: aliceblue;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  height: 50px;
  justify-content: center;
  margin: 3px 3px;
  text-transform: capitalize;
  width: 40px;
}

.Keyboard-letter.control {
  font-size: 20px;
  width: 60px;
}

.Keyboard-correct {
  background-color: var(--color-correct)
}

.Keyboard-wrong-place {
  background-color: var(--color-wrong-place)
}

.Keyboard-wrong {
  background-color: var(--color-wrong)
}