.Board-container {
  border-color: #538d4e;
  border-style: solid;
  border-width: 2px;
  padding: 5px;
}

.Board-row {
  align-items: center;
  display: flex;
}

.Board-shake {
  animation: shake-me-like-a-monkey .33s;
}

@keyframes shake-me-like-a-monkey {
  
  33% {
    transform: translateX(5%);
  }

  66% {
    transform: translateX(-5%);
  }
}