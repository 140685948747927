.BSODPage-Container {
  background-color: #0000aa;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BSODPage-Image {
  min-width: 50%;
}