.Letter-letter {
    font-family: "Clear Sans", "Helvetica Neue", Arial, sans-serif;
    font-size: xx-large;
    font-weight: bold;
}

.Letter-square {
    align-items: center;
    border: 1px solid black;
    display: flex;
    height: 60px;
    justify-content: center;
    margin: 3px;
    width: 60px;
    will-change: width, height;
    
    /* related to swell animation */
}

@import url('./vars.css');
.Letter-square.green {
    background: var(--color-correct);
}

.Letter-square.yellow {
    background: var(--color-wrong-place)
}

.Letter-square.gray {
    background: var(--color-wrong);
}

.Letter-square.black {
    background: var(--color-empty);
}

:root {
    --flip-animation-time: .3s;
    --win-animation-time: .1s;
}

.Letter-flip-animation {
    animation: flip-letter .3s;
}

.Letter-flip-animation.one {
    animation-delay: calc(var(--flip-animation-time) * 0);
    transition-delay: calc(var(--flip-animation-time) * 0.5);
}

.Letter-flip-animation.two {
    animation-delay: calc(var(--flip-animation-time) * 1);
    transition-delay: calc(var(--flip-animation-time) * 1.5);
}

.Letter-flip-animation.three {
    animation-delay: calc(var(--flip-animation-time) * 2);
    transition-delay: calc(var(--flip-animation-time) * 2.5);
}

.Letter-flip-animation.four {
    animation-delay: calc(var(--flip-animation-time) * 3);
    transition-delay: calc(var(--flip-animation-time) * 3.5);
}

.Letter-flip-animation.five {
    animation-delay: calc(var(--flip-animation-time) * 4);
    transition-delay: calc(var(--flip-animation-time) * 4.5);
}

.Letter-newInput {
    animation: box-swell .2s;
    border-color: #3a3a3c;
}

.Letter-gameOver {
    animation-duration: .3s;
    animation-name: game-over-jump;
}

.Letter-gameOver.one {
    animation-delay: calc(var(--win-animation-time) * 0);
    transition-delay: calc(var(--win-animation-time) * 0.5);
}

.Letter-gameOver.two {
    animation-delay: calc(var(--win-animation-time) * 1);
    transition-delay: calc(var(--win-animation-time) * 1.5);
}

.Letter-gameOver.three {
    animation-delay: calc(var(--win-animation-time) * 2);
    transition-delay: calc(var(--win-animation-time) * 2.5);
}

.Letter-gameOver.four {
    animation-delay: calc(var(--win-animation-time) * 3);
    transition-delay: calc(var(--win-animation-time) * 3.5);
}

.Letter-gameOver.five {
    animation-delay: calc(var(--win-animation-time) * 4);
    transition-delay: calc(var(--win-animation-time) * 4.5);
}


@keyframes game-over-jump {
    
    50% {
        transform: translateY(-20px);
    }
}

@keyframes box-swell {
    
    50% {
        height: 66px;
        margin: 0px;
        width: 66px;
        
        /* ugh, this is not ideal */
    }
}

@keyframes flip-letter {
    
    50% {
        height: 0px;
        transform: scale(1, 0)
    }

    100% {
        height: 60px;
        transform: scale(1, 1)
    }
}