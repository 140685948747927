.Toast-container {
  display: flex;
  justify-content: center;
}

.Toast-content {
  background-color: black;
  border-radius: 5px;
  color: azure;
  font-weight: 700;
  padding: 8px;
  position: fixed;
  top: 100px;
}